





















































import Vue from 'vue';
import Store from "@/store";
import IRegion from '../../models/region/IRegion';
import RegionProvider, { getCurrentRegion } from '../../api/RegionProvider';

interface IData {
    mobileOpenState: boolean;
    pserverInfo: IRegion|null;
}

export default Vue.extend({
    computed: {
        authed(): boolean {
            return this.$store.getters.authenticated;
        },
        reduceSidebar(): boolean {
            return process.env.VUE_APP_STATIC_REGION_REDUCE_SIDEBAR === 'true';
        },
        pserverHome(): string {
            return process.env.VUE_APP_STATIC_REGION_HOMEPAGE!;
        },
        pserverDiscord(): string {
            return process.env.VUE_APP_STATIC_REGION_DISCORD!;
        },
    },
    data(): IData {
        return {
            mobileOpenState: false,
            pserverInfo: null,
        };
    },
    watch: {
        ["$route"]() {
            this.setMenuState(false);
        }
    },
    async mounted() {
        if (process.env.VUE_APP_USE_STATIC_SERVER) {
            this.pserverInfo = await getCurrentRegion();
        }
    },
    methods: {
        toggleMenu() {
            this.setMenuState(!this.mobileOpenState);
        },
        setMenuState(state: boolean) {
            if (this.mobileOpenState && !state) {
                this.$anime({
                    targets: ".mobile",
                    opacity: ["1.0", "0.0"],
                    duration: 250,
                    easing: "easeOutCubic",
                }).finished.then(() => {
                    this.mobileOpenState = false;
                });
            } else if (!this.mobileOpenState && state) {
                this.mobileOpenState = true;
                Vue.nextTick().then(() => {
                    this.$anime({
                        targets: ".mobile",
                        opacity: ["0.0", "1.0"],
                        duration: 250,
                        easing: "easeInCubic",
                    });
                    this.$anime({
                        targets: ".mobile ul",
                        translateX: {
                            value: ["400", "0"],
                            duration: 100,
                            easing: "easeOutCubic",
                            elasticity: 500,
                        },
                        opacity: {
                            value: ["0.0", "1.0"],
                            duration: 250,
                            easing: "easeOutCubic",
                        },
                        delay: 150,
                    })
                });
            }
        }
    }
});
