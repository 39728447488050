


















import Vue from "vue";

export default Vue.extend({
    methods: {
        goBack() {
            this.$router.go(-1);
        }
    }
});
