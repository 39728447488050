





















import Vue from 'vue';
import GoBack from "@/components/util/GoBack.vue";
import UiString from "@/components/uistring/UiString.vue";
import StatCalcCard from "@/components/general/StatCalcCard.vue";

import StatCalcProvider,{ IStatCalcResult } from "@/api/StatCalcProvider";
import { getFeatureState, netFeatures } from '../../api/FeaturesProvider';

interface IData {
    stat: string;
    value: number;
}

export default Vue.extend({
    components: {
        GoBack,
        UiString,
        StatCalcCard,
    },
    data(): IData {
        return {
            stat: "",
            value: 0,
        };
    },
    computed: {
        hideCritDamage(): boolean {
            return getFeatureState(netFeatures.Use40Cap);
        },
    },
    created() {
    }
});
