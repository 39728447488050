var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"discord-hero"},[(_vm.loginStatus == 3)?_c('div',{staticClass:"discord-success"},[_vm._v(" Success! You are now logged in. ")]):(_vm.loginStatus == 0)?_c('div',{staticClass:"discord-login"},[_c('button',{directives:[{name:"anime",rawName:"v-anime",value:({
                borderColor: ['#98BCD4', '#dcf1ff', '#98BCD4', '#98BCD4'],
                loop: true,
                duration: 3000,
                delay: 750,
                easing: 'easeInOutExpo'
            }),expression:"{\n                borderColor: ['#98BCD4', '#dcf1ff', '#98BCD4', '#98BCD4'],\n                loop: true,\n                duration: 3000,\n                delay: 750,\n                easing: 'easeInOutExpo'\n            }"}],staticClass:"dv-button discord-button button",on:{"click":_vm.openDiscordAuth}},[_c('div',{staticClass:"login-label"},[_vm._v("Log in")])]),_c('br'),(_vm.optional)?_c('div',{staticClass:"no-thanks"},[_c('a',{attrs:{"href":"#"},on:{"click":_vm.skip}},[_vm._v("No thanks, just let me in")])]):_vm._e()]):(_vm.loginStatus == 1)?_c('div',{directives:[{name:"anime",rawName:"v-anime",value:({
            translateX: [-50, 0],
            opacity: {
                value: ['0.0', '1.0'],
                duration: 5000,
            },
            delay: 500,
            duration: 750,
            elasticity: 100,
        }),expression:"{\n            translateX: [-50, 0],\n            opacity: {\n                value: ['0.0', '1.0'],\n                duration: 5000,\n            },\n            delay: 500,\n            duration: 750,\n            elasticity: 100,\n        }"}],staticClass:"discord-waiting"},[_c('div',{staticClass:"loading-blinker"}),_vm._v(" Waiting for you to log in on the Devlin popup window... ")]):(_vm.loginStatus == 2)?_c('div',{staticClass:"discord-waiting"},[_c('div',{staticClass:"loading-blinker"}),_vm._v(" Verifying login information... ")]):(_vm.loginStatus == 4)?_c('div',{staticClass:"discord-error"},[_c('h4',{staticClass:"head"},[_vm._v(" Whoops, we couldn't log you in :( ")]),(_vm.discordLoginError)?_c('div',{staticClass:"detail"},[_vm._v(" "+_vm._s(_vm.discordLoginError.message)+" ")]):_vm._e(),_c('div',{staticClass:"button-row"},[_c('button',{staticClass:"dv-button",on:{"click":_vm.openDiscordAuth}},[_vm._v("Try again")]),(_vm.optional)?_c('button',{staticClass:"dv-button",on:{"click":_vm.skip}},[_vm._v("Skip for now")]):_vm._e()])]):_vm._e(),(_vm.loginStatus == 3)?_c('div',{staticClass:"got-it"},[_c('button',{directives:[{name:"anime",rawName:"v-anime",value:({
                    borderColor: ['#98BCD4', '#dcf1ff', '#98BCD4', '#98BCD4'],
                    loop: true,
                    duration: 3000,
                    delay: 750,
                    easing: 'easeInOutExpo'
                }),expression:"{\n                    borderColor: ['#98BCD4', '#dcf1ff', '#98BCD4', '#98BCD4'],\n                    loop: true,\n                    duration: 3000,\n                    delay: 750,\n                    easing: 'easeInOutExpo'\n                }"}],staticClass:"dv-button",on:{"click":_vm.finish}},[_vm._v(" Awesome, let's go ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }