































































import Vue from "vue";
import store from "./store";

import FirstRun from "@/views/FirstRun.vue";
import NavigationPane from "@/components/site/NavigationPane.vue";
import SiteAuthHeader from "@/components/auth/SiteAuthHeader.vue";
import RegionHeader from "@/components/region/RegionHeader.vue";
import Loader from "@/components/util/Loader.vue";

export default Vue.extend({
    name: "app",
    components: {
        FirstRun,
        NavigationPane,
        SiteAuthHeader,
        RegionHeader,
        Loader,
    },
    data() {
        return {
            started: false,
        };
    },
    computed: {
        embedded(): boolean {
            return !!this.$route.query.embed;
        },
        showFirstRun(): boolean {
            return !this.embedded && this.$store.getters.showFirstRun && !this.isRedeemingCode;
        },
        isRedeemingCode(): boolean {
            return this.$route.matched.some((r) => r.meta.redemption);
        },
        showRouteLoader(): boolean {
            return this.$store.state.pageLoading;
        },
        hideAuth(): boolean {
            return this.$route.meta.redemption;
        },
        useInstanceText(): string {
            return process.env.VUE_APP_USE_STATIC_SERVER || '';
        }
    },
    mounted() {
        const shade = document.getElementById("startup-shade");
        if (shade) {
            window.setTimeout(() => {
                shade.classList.add("hidden");
            }, 500);
        }

        const referrer = document.referrer;
        if (referrer.includes('dnreturn.com')) {
            window.location.replace("https://fatduckdn.com");
        }

        this.started = true;
    },
    methods: {
    }
});
