

















































import Vue from 'vue'
import LoadingErrorable from "@/models/util/LoadingErrorable";
import UiStringProvider, { UiStringFormat } from '@/api/UiStringProvider';
import IUiStringMessage from '@/models/uistring/IUiStringMessage';
import RegionProvider,{ defaultRegion } from '@/api/RegionProvider';
import IRegion from '@/models/region/IRegion';
import store from '@/store';

interface IAltRegionMessage {
    region: IRegion;
    message: IUiStringMessage;
}

interface IData {
    message: LoadingErrorable<IUiStringMessage, any>;
    altRegionMessages: IAltRegionMessage[]|null;
    showModal: boolean;
    delayShowModal: boolean;
}

export default Vue.extend({
    name: "ui-string",
    props: {
        "mid": {
            type: [String as () => string, Number as () => number],
        },
        "params": {
            type: String as () => string,
        },
        "format": {
            type: String as () => UiStringFormat,
        },
        "region": {
            type: String as () => string,
        },
        "inline": {
            type: Boolean as () => boolean,
        },
        "alt": {
            type: String as () => string,
        },
        "messageData": {
            type: Object as () => IUiStringMessage,
        },
        "preventRecursive": {
            type: Boolean as () => boolean,
            default: false,
        },
    },
    data(): IData {
        return {
            message: new LoadingErrorable(),
            altRegionMessages: null,
            showModal: false,
            delayShowModal: false,
        };
    },
    watch: {
        mid(newMid, oldMid) {
            if (newMid != oldMid) {
                this.fetchData();
            }
        },
        params(newParams, oldParams) {
            if (newParams != oldParams) {
                this.fetchData();
            }
        },
        format(newFormat, oldFormat) {
            if (newFormat != oldFormat) {
                this.fetchData();
            }
        },
        region(newRegion, oldRegion) {
            if (newRegion != oldRegion) {
                this.fetchData();
            }
        },
        messageData(newProv, oldProv) {
            if (newProv != oldProv) {
                this.altRegionMessages = null;
                this.fetchData();
            }
        },
    },
    created() {
        this.fetchData();
    },
    computed: {
        id(): number {
            return Number(this.mid) || (this.messageData && this.messageData.id);
        },
        resolvedRegion(): string {
            return this.region || store.state.regionCode || defaultRegion;
        },
        title() {
            if (!this.preventRecursive) {
                return "Click to view other translations";
            }

            return null;
        }
    },
    methods: {
        fetchData() {
            if (this.messageData) {
                this.message.done(this.messageData);
                return;
            }

            if (this.message.loading) {
                return;
            }

            this.altRegionMessages = null;
            
            this.message.startLoad();
            UiStringProvider.get(this.id, this.region, this.params, this.format).then((msg) => {
                this.message.done(msg);
            }).catch((err) => {
                this.message.failed(err);
            });
        },
        async loadOtherRegionData() {
            const regions: IRegion[] = (await RegionProvider.listRegions()).filter((r) => r.shortName != this.resolvedRegion);
            if (regions.length === 0) {
                this.altRegionMessages = null;
                return;
            }

            this.altRegionMessages = [];
            let promises: Promise<IAltRegionMessage>[] = regions.map(async (region) => {
                const message = await UiStringProvider.get(this.id, region.shortName, this.params, this.format);
                return {
                    message,
                    region,
                };
            });
            this.altRegionMessages = await Promise.all(promises);
        },
        async onClick() {
            if (this.preventRecursive) {
                return;
            }

            this.showModal = true;
            Vue.nextTick().then(() => {
                this.delayShowModal = true;
            });
            if (this.altRegionMessages == null) {
                await this.loadOtherRegionData();
            }
                
            Vue.nextTick().then(() => this.positionModal());
        },
        dismissModal() {
            this.showModal = false;
            this.delayShowModal = false;
        },
        async positionModal() {
            if (this.showModal) {
                const element = this.$refs.modal as HTMLDivElement;
                if (element) {
                    const bb = element.getBoundingClientRect();
                    if (bb) {
                        // Check that the tooltip is not going past the bottom of the page
                        const delta = bb.bottom - document!.documentElement!.clientHeight;
                        // console.log(delta);
                        if (delta > 0) {
                            await this.$anime({
                                targets: [element],
                                translateY: [-delta, -delta - 50],
                                elasticity: 0,
                                duration: 0,
                            }).finished;
                        }
                    }
                }
            }
        }
    }
});
