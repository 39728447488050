






























































import Vue from "vue";
import Loader from "@/components/util/Loader.vue";
import UiString from "@/components/uistring/UiString.vue";

import LoadingErrorable from "@/models/util/LoadingErrorable";
import IRegion from "@/models/region/IRegion";

import RegionProvider from "@/api/RegionProvider";

interface IData {
    regionInfo: LoadingErrorable<IRegion, any>;
}

export default Vue.extend({
    components: {
        Loader,
        UiString,
    },
    data(): IData {
        return {
            regionInfo: new LoadingErrorable(), 
        };
    },
    created() {
        this.fetchData();
    },
    computed: {
        pserverHome(): string {
            return process.env.VUE_APP_STATIC_REGION_HOMEPAGE!;
        },
        pserverDiscord(): string {
            return process.env.VUE_APP_STATIC_REGION_DISCORD!;
        },
        pserverDonate(): string {
            return process.env.VUE_APP_STATIC_REGION_DONATE!;
        },
        pserverName(): string {
            return (this.regionInfo.value && this.regionInfo.value.displayNames.default) || '';
        },
        staticServer(): string {
            return process.env.VUE_APP_USE_STATIC_SERVER!;
        },
    },
    methods: {
        fetchData() {
            this.regionInfo.startLoad();
            RegionProvider.getRegionByShortName(this.$store.state.regionCode).then((region) => {
                this.regionInfo.done(region);
            }).catch((error) => {
                this.regionInfo.error(error);
            });
        }
    }
})
